import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
// import Table, {TableBody, TableCell, TableHead, TableRow} from '@material-ui/core/Table';
// import Paper from '@material-ui/core/Paper';
import {TextField, Typography, Button,Toolbar} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import FormGroup from '@material-ui/core/FormGroup';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';

import classNames from 'classnames';
import Utils from '../api/api';
import theme,{styles} from '../layout/theme';

class ReactPressPropertyDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      _id:'',
      property: {
        nameVN: '',
        nameEN: '',
        //nameKR: '',
        valueVN: '',
        valueEN: '',
        //valueKR: '',
      },
      error: {
        vn: false,
        en: false,
        //kr: false,
        nameVN_error: false,
        nameEN_error: false,
        //nameKR_error: false,
        valueVN_error: false,
        valueEN_error: false,
        //valueKR_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false
    }
  }
  componentDidMount(){
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;
    if(params.id!==undefined){
      this.setState({isUpdate:true},function(){
        Utils.getSingleData('property',params.id,function(res){
          if(res.status=='success'&&res.results!==undefined){
            $this.setState({property:res.results},function(){
              //console.log($this.state);
            });
          }
        });
      });
    }
  }
  handleValidate(e,flag){
    var $this = this,
        property = this.state.property,
        obj = this.state.error;
    
        property[e.target.id] = e.target.value.trim();
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() == '' || e.target.value == null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;
    this.setState({property: property, error:obj}, () => {
      if(!$this.state.error.nameVN_error && !$this.state.error.valueVN_error){
        obj['vn'] = false;
      }else{
        obj['vn'] = true;
      }
      if(!$this.state.error.nameEN_error && !$this.state.error.valueEN_error){
        obj['en'] = false;
      }else{
        obj['en'] = true;
      }
      // if(!$this.state.error.nameKR_error && !$this.state.error.valueKR_error){
      //   obj['kr'] = false;
      // }else{
      //   obj['kr'] = true;
      // }
      $this.setState({error:obj});
    });
  }
  _save(){
    var $this = this;
    var data = this.state.property;
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('property',data, function(res) {
        //console.log(res);
        if (res.status == 'success') {
          $this.context.router.history.push('/property/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('property',data, function(res) {
        
        if (res.status == 'success') {
          $this.context.router.history.push('/property/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag){
    var params = this.context.router.route.match.params;
    
    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          obj['nameVN_error'] = this.state.property.nameVN === ''||this.state.property.valueVN === '';
          obj['vn'] = obj['nameVN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        case 'en':
          obj['nameEN_error'] = this.state.property.nameEN === ''||this.state.property.valueEN === '';
          obj['en'] = obj['nameEN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        // case 'kr':
        //   obj['nameKR_error'] = this.state.property.nameKR === ''||this.state.property.valueKR === '';
        //   obj['kr'] = obj['nameKR_error'];
        //   this.setState({error:obj},function(){
        //     if(!$this.state.error[flag]) $this._save();
        //   });
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel(){
    this.context.router.history.push('/property/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  };
  render(){
    const classes = this.props.classes;
    const value = this.state.tab;
    return(
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa Nhóm đặc tính</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="nameVN" label={language.VN.name} className={classes.textField} value={this.state.property.nameVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.nameVN_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <TextField id="valueVN" label={language.VN.value} className={classes.textField} value={this.state.property.valueVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.valueVN_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="nameEN" label={language.EN.name} className={classes.textField} value={this.state.property.nameEN} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.nameEN_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <TextField id="valueEN" label={language.EN.value} className={classes.textField} value={this.state.property.valueEN} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.nameEN_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {/* {value === 2 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.kr && classes.hide)}>{language.KR.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="nameKR" label={language.KR.name} className={classes.textField} value={this.state.property.nameKR} onChange={(event) => this.handleValidate(event,'kr')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.nameKR_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <TextField id="valueKR" label={language.KR.value} className={classes.textField} value={this.state.property.valueKR} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.nameEN_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.KR.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'kr')}>{language.KR.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>} */}
      </div>
    )
  }
}

ReactPressPropertyDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
ReactPressPropertyDetail.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(ReactPressPropertyDetail);
